import { useContext } from "react";
import { PanelContext } from "../../../Context/PanelContext";
import adminLogo from "../../../../../assets/AdminLogo.png";
import users from "../../../../../assets/Users.png";

export default function AdminPanelSideBar() {
  const { adminMenu, setAdminMenu } = useContext(PanelContext);

  return (
    <>
      <div className="AdminPanelSideBar">
        <div className="AdminPanelSideBar-logo">
          <img src={adminLogo} alt="SpeakingRose" />
        </div>
        <div className="AdminPanelSideBar-list">
          <div
            className="AdminPanelSideBar-list-item"
            style={{
              backgroundColor: adminMenu != 2 ? "#343A40" : "transparent",
            }}
            onClick={() => {
                setAdminMenu(1);
            }}
          >
            <div className="AdminPanelSideBar-list-item-logo">
              <img src={users} alt="users" />
            </div>
            <div className="AdminPanelSideBar-list-item-text">3D Viewer</div>
          </div>

          <div
            className="AdminPanelSideBar-list-item"
            style={{
              backgroundColor: adminMenu == 2 ? "#343A40" : "transparent",
            }}
            onClick={() => {
                setAdminMenu(2);
            }}
          >
            <div className="AdminPanelSideBar-list-item-logo">
              <img src={users} alt="users" />
            </div>
            <div className="AdminPanelSideBar-list-item-text">
              User Managment
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
