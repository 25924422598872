import { createContext, useState } from "react";

export const PanelContext = createContext();

export default function PanelContextProvider(props) {
  const [adminMenu, setAdminMenu] = useState(13);

  const [roseTypeImage, setRoseTypeImage] = useState(null);
  const [addFont, setAddFont] = useState(null);
  const [add3DFile, setAdd3DFile] = useState(null);
  const [addImage, setAddImage] = useState(null);

  const contextValue = {
    adminMenu,
    setAdminMenu,
    roseTypeImage,
    setRoseTypeImage,
    addFont,
    setAddFont,
    add3DFile,
    setAdd3DFile,
    addImage,
    setAddImage,
  };

  return (
    <PanelContext.Provider value={contextValue}>
      {props.children}
    </PanelContext.Provider>
  );
}
