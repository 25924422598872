import logo from "../../../assets/LoginLogo.png";

export default function Login() {
  return (
    <>
      <div className="Login">
        <div className="Login-container">
          <div>
            <div className="Login-logo">
              <img src={logo} alt="SpeakigRose" />
            </div>
            <div className="Login-title">
              <h6>Sign in</h6>
            </div>
            <div className="Login-text">
              <p>Use Login information!</p>
            </div>

            <form action="" className="Login-form">
              <div className="Login-form-user">
                <label for="username" className="form-label">
                  User Name
                </label>
                <input type="text" className="form-control" id="username" />
              </div>
              <div className="Login-form-pass">
                <label for="password" className="form-label">
                  Password
                </label>
                <input type="password" className="form-control" id="password" />
              </div>
              <button className="Login-form-submit">Login</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
