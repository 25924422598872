import { useContext } from "react";
import card1 from "../../../../../assets/panelCard1.png";
import card2 from "../../../../../assets/panelCard2.png";
import card3 from "../../../../../assets/panelCard3.png";
import { PanelContext } from "../../../Context/PanelContext";

export default function ThreeDviewer() {

    const { adminMenu, setAdminMenu } = useContext(PanelContext);

  return (
    <>
      <div className="ThreeDviewer">
        <div className="ThreeDviewer-title">
          <h4>3D viewer details</h4>
        </div>
        <div className="ThreeDviewer-content">
          <section className="ThreeDviewer-card" onClick={() => {
            setAdminMenu(11);
          }}>
            <div className="ThreeDviewer-card-image">
              <img src={card1} alt="Rose Configuration" />
            </div>
            <div>
              <p className="ThreeDviewer-card-title">Rose Configuration</p>

              <p className="ThreeDviewer-card-subTitle">
                Define colors of Roses.
              </p>
            </div>
          </section>

          <section className="ThreeDviewer-card">
            <div className="ThreeDviewer-card-image">
              <img src={card2} alt="ِDesign Configuration" style={{
                width : "58%"
              }} />
            </div>
            <div>
              <p className="ThreeDviewer-card-title">ِDesign Configuration</p>

              <p className="ThreeDviewer-card-subTitle">
                Define font type and colors of fonts.
              </p>
            </div>
          </section>

          <section className="ThreeDviewer-card">
            <div className="ThreeDviewer-card-image">
              <img src={card3} alt="Packaging Configuration" style={{
                width : "58%"
              }}/>
            </div>
            <div>
              <p className="ThreeDviewer-card-title">Packaging Configuration</p>

              <p className="ThreeDviewer-card-subTitle">
                Define Rose type and colors of each type.
              </p>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
