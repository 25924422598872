import { useContext, useRef, useState, react } from "react";
import { PanelContext } from "../../../../Context/PanelContext";
import icon7 from "../../../../../../assets/icon7.png";
import text from "../../../../../../assets/FontImg.png";

export default function ModalAddBox() {
  const { add3DFile, setAdd3DFile, addImage, setAddImage } =
    useContext(PanelContext);

  const [dragActive, setDragActive] = useState(false);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAdd3DFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const inputRef = useRef(null);

  const handleFontChange = (e) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAdd3DFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleRemove = () => {
    setAdd3DFile(null);
  };

  return (
    <div
      className="modal fade ModalAddBox"
      id="ModalAddBox"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Add New Box
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="Body-row1">
              <section className="Body-row1-item">
                <label for="NameOfType" className="form-label">
                  Box Material:
                </label>
                <div>
                  <select
                    className="form-select custom-select"
                    aria-label="Default select example"
                    onClick={toggleDropdown1}
                  >
                    <option selected>Glass</option>
                    <option value="1">Paper</option>
                  </select>
                  <div
                    className="custom-icon"
                    style={{
                      transform: isOpen1
                        ? "translateY(-50%) rotate(180deg)"
                        : "translateY(-50%) rotate(0deg)",
                      top: isOpen1 ? "50%" : "60%",
                    }}
                  ></div>
                </div>
              </section>

              <section className="Body-row1-item">
                <label for="NameOfType" className="form-label">
                  Type:
                </label>
                <div>
                  <select
                    className="form-select custom-select"
                    aria-label="Default select example"
                    onClick={toggleDropdown2}
                  >
                    <option selected>Rose</option>
                    <option value="1">Petals</option>
                  </select>
                  <div
                    className="custom-icon"
                    style={{
                      transform: isOpen2
                        ? "translateY(-50%) rotate(180deg)"
                        : "translateY(-50%) rotate(0deg)",
                      top: isOpen2 ? "50%" : "60%",
                    }}
                  ></div>
                </div>
              </section>
            </div>

            <div className="Body-row2">
              <div className="Number">
                <label for="Number" className="form-label">
                  Number
                </label>
                <input type="text" className="form-control" id="Number" />
              </div>
            </div>

            <div className="UploadInput1">
              <label for="UploadInput" className="form-label">
                Upload 3D File:
              </label>
              <div
                className="UploadInput-div"
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                onDragLeave={handleDrag}
              >
                {add3DFile ? (
                  <div className="UploadInput1-show">
                    <img src={text} alt="roseTypeImage" />
                    <div
                      className="UploadInput1-show-closeBtn"
                      onClick={handleRemove}
                    >
                      <i class="bi bi-x-lg"></i>
                    </div>
                  </div>
                ) : (
                  <div
                    className="UploadInput1-div-drag"
                    onDrop={handleDrop}
                    onClick={handleClick}
                  >
                    <img src={icon7} alt="image1" />
                    <span>Drag file here or</span>
                    <button className="UploadInput1-div-drag-browseBtn">
                      Brows Files
                    </button>
                    <input
                      id="UploadInput"
                      type="file"
                      ref={inputRef}
                      onChange={handleFontChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="modal-footer SaveBtn">
            <button type="button" className="">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
