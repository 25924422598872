import { useContext, useRef, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import left from "../../../../../../../assets/left.png";
import icon7 from "../../../../../../../assets/icon7.png";

export default function AddImage() {
  const { setDesignPanelMenu, workSpaceList, setWorkSpaceList } =
    useContext(HomeContext);

  const [activeTab, setActiveTab] = useState("New");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const [dragActive, setDragActive] = useState(false);

  // const handleDrag = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (e.type === "dragenter" || e.type === "dragover") {
  //     setDragActive(true);
  //   } else if (e.type === "dragleave") {
  //     setDragActive(false);
  //   }
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setDragActive(false);
  //   const file = e.dataTransfer.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImage(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  return (
    <>
      <div className="AddImage">
        <div className="AddImage-container">
          <div className="AddImage-title">
            <div
              className="AddImage-title-backIcon"
              onClick={() => {
                setDesignPanelMenu(3);
              }}
            >
              <img src={left} alt="left" />
            </div>
            <span>Add Images</span>
          </div>

          <div className="AddImage-content">
            <div>
              <div className="AddImage-tab">
                <div
                  onClick={() => handleTabClick("New")}
                  className="AddImage-tab-item"
                  style={{
                    borderBottom:
                      activeTab === "New"
                        ? "2.5px solid #B04953"
                        : "2.5px solid #9C9C9C",
                    fontWeight: activeTab === "New" ? "bold" : "normal",
                    color: activeTab === "New" ? "#171A20" : "#5C5E62",
                    paddingRight: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  New Image
                </div>
                <div
                  onClick={() => handleTabClick("History")}
                  className="AddImage-tab-item"
                  style={{
                    borderBottom:
                      activeTab === "History"
                        ? "2.5px solid #B04953"
                        : "2.5px solid #9C9C9C",
                    fontWeight: activeTab === "History" ? "bold" : "normal",
                    color: activeTab === "History" ? "#171A20" : "#5C5E62",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  History
                </div>
              </div>

              <div className="AddImage-tab-content">
                {activeTab === "New" && (
                  <div className="New-image">
                    <div
                      className="ImageTab"
                      // onDragEnter={handleDrag}
                      // onDragOver={handleDrag}
                      // onDragLeave={handleDrag}
                    >
                      <div
                        className="ImageTab-drag"
                        // onDrop={handleDrop}
                        // onClick={handleClick}
                        onClick={() => {
                          setDesignPanelMenu(321);
                        }}
                      >
                        <img src={icon7} alt="image1" />
                        <button className="ImageTab-drag-browseBtn">
                          Brows Files
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "History" && (
                  <div className="History-image"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
