import { useContext, useEffect, useRef, useState } from "react";
import icon2 from "../../../../assets/icon2.png";
import icon3 from "../../../../assets/icon3.png";
import icon4 from "../../../../assets/icon4.png";
import icon5 from "../../../../assets/icon5.png";
import icon51 from "../../../../assets/icon5+.png";
import icon6 from "../../../../assets/icon6.png";
import { HomeContext } from "../../Context/HomeContext";

export default function DesignPanelToggler() {
  const { designPanelMenu, setDesignPanelMenu } = useContext(HomeContext);

  let item1 = "";
  let item2 = "";
  let item3 = "";
  let item4 = "";
  let divider1 = "";
  let divider2 = "";
  let divider3 = "";

  const handleStyle = () => {
    if (designPanelMenu === 1) {
      item1 = "PanelToggler-item-icon ActiveI";
      item2 = "PanelToggler-item-icon FutureI";
      item3 = "PanelToggler-item-icon FutureI";
      item4 = "PanelToggler-item-icon FutureI";
      divider1 = "FutureD";
      divider2 = "FutureD";
      divider3 = "FutureD";
    } else if (designPanelMenu === 2) {
      item1 = "PanelToggler-item-icon PastI";
      item2 = "PanelToggler-item-icon ActiveI";
      item3 = "PanelToggler-item-icon FutureI";
      item4 = "PanelToggler-item-icon FutureI";
      divider1 = "PastD";
      divider2 = "FutureD";
      divider3 = "FutureD";
    } else if (
      designPanelMenu === 3 ||
      designPanelMenu === 31 ||
      designPanelMenu === 32 ||
      designPanelMenu === 33 ||
      designPanelMenu === 321 ||
      designPanelMenu === 322
    ) {
      item1 = "PanelToggler-item-icon PastI";
      item2 = "PanelToggler-item-icon PastI";
      item3 = "PanelToggler-item-icon ActiveI";
      item4 = "PanelToggler-item-icon FutureI";
      divider1 = "PastD";
      divider2 = "PastD";
      divider3 = "FutureD";
    } else if (designPanelMenu === 4) {
      item1 = "PanelToggler-item-icon PastI";
      item2 = "PanelToggler-item-icon PastI";
      item3 = "PanelToggler-item-icon PastI";
      item4 = "PanelToggler-item-icon ActiveI";
      divider1 = "PastD";
      divider2 = "PastD";
      divider3 = "PastD";
    } else {
      item1 = "PanelToggler-item-icon FutureI";
      item2 = "PanelToggler-item-icon FutureI";
      item3 = "PanelToggler-item-icon FutureI";
      item4 = "PanelToggler-item-icon FutureI";
      divider1 = "FutureD";
      divider2 = "FutureD";
      divider3 = "FutureD";
    }
  };
  handleStyle();

  useEffect(() => {
    handleStyle();
  }, [designPanelMenu]);

  return (
    <>
      <div className="PanelToggler">
        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {designPanelMenu === 1 && <img src={icon6} alt="pointer" />}
          </div>

          <div
            className={item1}
            onClick={() => {
              setDesignPanelMenu(1);
            }}
          >
            <img src={icon2} alt="Choose Type" width="24px" height="24px" />
          </div>
          <div
            className="PanelToggler-item-text"
            style={{
              fontWeight: designPanelMenu === 1 ? "900" : "700",
            }}
          >
            Choose Type
          </div>
        </section>

        <div className="PanelToggler-divider">
          <div className={divider1}></div>
        </div>

        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {designPanelMenu === 2 && <img src={icon6} alt="pointer" />}
          </div>
          <div
            className={item2}
            onClick={() => {
              setDesignPanelMenu(2);
            }}
          >
            <img src={icon3} alt="Choose Color" width="18px" height="18px" />
          </div>
          <div
            className="PanelToggler-item-text"
            style={{
              fontWeight: designPanelMenu === 2 ? "900" : "700",
            }}
          >
            Choose Color
          </div>
        </section>

        <div className="PanelToggler-divider">
          <div className={divider2}></div>
        </div>

        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {designPanelMenu === 3 ||
            designPanelMenu === 31 ||
            designPanelMenu === 32 ||
            designPanelMenu === 33 ||
            designPanelMenu === 321 ||
            designPanelMenu === 322 ? (
              <img src={icon6} alt="pointer" />
            ) : null}
          </div>
          <div
            className={item3}
            onClick={() => {
              setDesignPanelMenu(3);
            }}
          >
            <img src={icon4} alt="Design Rose" width="18px" height="18px" />
          </div>
          <div
            className="PanelToggler-item-text"
            style={{
              fontWeight:
                designPanelMenu === 3 ||
                designPanelMenu === 31 ||
                designPanelMenu === 32 ||
                designPanelMenu === 33 ||
                designPanelMenu === 321 ||
                designPanelMenu === 322
                  ? "900"
                  : "700",
            }}
          >
            Design Rose
          </div>
        </section>

        <div className="PanelToggler-divider">
          <div className={divider3}></div>
        </div>

        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {designPanelMenu === 4 && <img src={icon6} alt="pointer" />}
          </div>
          <div
            className={item4}
            onClick={() => {
              setDesignPanelMenu(4);
            }}
          >
            {designPanelMenu === 4 ? (
              <img src={icon51} alt="Packaging" width="20px" height="20px" />
            ) : (
              <img src={icon5} alt="Packaging" width="20px" height="20px" />
            )}
          </div>
          <div
            className="PanelToggler-item-text"
            style={{
              fontWeight: designPanelMenu === 4 ? "900" : "700",
            }}
          >
            Packaging
          </div>
        </section>
      </div>
    </>
  );
}
