import { useState } from "react";

export default function ModalAddUser() {
  const [newUser, setNewUser] = useState();

  return (
    <div
      className="modal fade ModalAddUser"
      id="ModalAddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Add New User Access
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="UserName">
              <label for="UserName" className="form-label">
                User Name
              </label>
              <input type="text" className="form-control" id="UserName" />
            </div>

            <div className="Password">
              <label for="PasswordNew" className="form-label">
                Password
              </label>
              <input type="text" className="form-control" id="PasswordNew" />
            </div>

            <div className="Email">
              <label for="EmailNew" className="form-label">
                Email
              </label>
              <input type="email" className="form-control" id="EmailNew" />
            </div>
          </div>

          <div className="modal-footer SaveBtn">
            <button type="button" className="">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
