import { useContext } from "react";
import AdminPanelSideBar from "./Components/SideBar/SideBar";
import { PanelContext } from "../Context/PanelContext";
import ThreeDviewer from "./Components/3Dviewer/ThreeDviewer";
import RoseConfiguration from "./Components/RoseConfiguration/RoseConfiguration";
import DesignConfiguration from "./Components/DesignConfiguration/DesignConfiguration";
import PackagingConfiguration from "./Components/PackagingConfiguration/PackagingConfiguration";
import UserManagment from "./Components/UserManagment/UserManagment";

export default function AdminPanel() {
  const { adminMenu, setAdminMenu } = useContext(PanelContext);

  return (
    <>
      <div className="AdminPanel">
        <AdminPanelSideBar />
        {adminMenu === 1 && <ThreeDviewer />}

        {adminMenu === 11 && <RoseConfiguration />}

        {adminMenu === 12 && <DesignConfiguration />}

        {adminMenu === 13 && <PackagingConfiguration />}

        {adminMenu === 2 && <UserManagment />}
      </div>
    </>
  );
}
