import { useContext, useRef, useState, react } from "react";
import { PanelContext } from "../../../../Context/PanelContext";
import icon7 from "../../../../../../assets/icon7.png";

export default function ModalAddBoxType() {
  const { roseTypeImage, setRoseTypeImage } = useContext(PanelContext);

  return (
    <div
      className="modal fade ModalAddBoxType"
      id="ModalAddBoxType"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Add New Box Material
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="NameMaterial">
              <label for="NameOfType" className="form-label">
                Name of Material:
              </label>
              <input type="text" className="form-control" id="NameOfType" />
            </div>
          </div>

          <div className="modal-footer SaveBtn">
            <button type="button" className="">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
