import { useContext, useEffect, useRef, useState } from "react";
import logo from "../../../../assets/logo.png";
import bIcon1 from "../../../../assets/bIcon1.png";
import bIcon2 from "../../../../assets/bIcon2.png";
import bIcon3 from "../../../../assets/bIcon3.png";
import * as THREE from "three";
import { RoomEnvironment } from "three/addons/environments/RoomEnvironment.js";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';  // Import RGBELoader

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { HomeContext } from "../../Context/HomeContext";
import { normalize } from "three/src/math/MathUtils";

export default function CanvasPart() {
  const canvasRef = useRef();
  const modelRef = useRef([]);
  const sceneRef = useRef();
  const cameraRef = useRef();
  const orbitRef = useRef();
  const rendererRef = useRef();
  const firstLoad = useRef(true);
  const [loadModel, setLoadModel] = useState(false);

  let newBaseColorTexture;
  let newNormalMapTexture, newNormalMapBaseRose;
  let newHeightMapTexture;
  let newRoughnessMapTexture;

  const zoomStep = 0.2;
  const minZoomDistance = 1;
  const maxZoomDistance = 10;

  const {
    colorCode,
    baseColorCode,
    colorRoseSelected,
    text,
    fontFamilyPath,
    fontFamily,
    fontSize,
    selectedColor,
    loadingMain,
    setLoadingMain,
    roseType,
    material,
    quantity,
    boxSelected,
  } = useContext(HomeContext);

  let mainOption;

  const logCameraPosition = () => {
    if (cameraRef.current) {
      const { x, y, z } = cameraRef.current.position;
      // console.log(`Camera Position - x: ${x}, y: ${y}, z: ${z}`);
    }
  };

  // function waitForFontToLoad(fontFamily) {
  //   return new Promise((resolve) => {
  //     document.fonts.load(`10pt ${fontFamily}`).then(resolve);
  //   });
  // }

  function waitForFontToLoad(fontFamily) {
    return new Promise((resolve, reject) => {
      document.fonts
        .load(`10pt ${fontFamily}`)
        .then(() => {
          console.log("Font successfully loaded:", fontFamily);
          resolve();
        })
        .catch((error) => {
          console.error("Error loading font:", error);
          reject(error);
        });
    });
  }

  const loadText = (mainText) => {
    console.log("loadText");
    let fontSize1 = fontSize;
    let fontFamily1 = fontFamily;
    let selectedColor1 = selectedColor;

    async function loadFont(fontFamilyPath, callback) {
      const newStyle = document.createElement("style");
      newStyle.appendChild(
        document.createTextNode(`
        @font-face {
          font-family: '${fontFamily1}';
          src: url('${fontFamilyPath}');
        }
      `)
      );
      document.head.appendChild(newStyle);

      const testCanvas = document.createElement("canvas");
      const testCtx = testCanvas.getContext("2d");
      testCtx.font = `${fontSize1} ${fontFamily1}`;

      // Wait for the font to load
      try {
        await waitForFontToLoad(fontFamily1);
        console.log("Font loaded successfully:", fontFamily1);
        callback();
      } catch (error) {
        console.error("Error while loading font:", error);
      }

      callback();
    }

    function createTextTexture(text1, options = {}) {
      const {
        width = 1000,
        height = 1000,
        // fillStyle = "#000",
        // fontSize = 48, // Font size can be customized
        // fontFamily = "Roboto" , // You can change the font family here
        textAlign = "center",
        textBaseline = "middle",
        bold = false,
        italic = false,
      } = options;

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, width, height);

      ctx.translate(0, height); // Move the origin to the bottom-left corner
      ctx.scale(1, -1); // Flip the canvas along the Y-axis

      ctx.font = `${italic ? "italic" : ""} ${bold ? "bold" : ""
        } ${fontSize1}px ${fontFamily1}`;
      ctx.fillStyle = selectedColor1;
      ctx.textAlign = textAlign;
      ctx.textBaseline = textBaseline;

      ctx.fillText(text1, width / 2, height / 2);

      const texture = new THREE.CanvasTexture(canvas);
      return texture;
    }

    loadFont(fontFamilyPath, () => {
      const flowerMesh2 = modelRef.current.getObjectByName("textlayer");
      if (flowerMesh2) {
        const textTexture2 = createTextTexture(mainText, mainOption);
        flowerMesh2.material = new THREE.MeshBasicMaterial({
          map: textTexture2,
          transparent: true,
        });
      }

      flowerMesh2.material.needsUpdate = true;
    });
  };

  function updateMeshBaseColorTexture(
    baseColorTexturePath,
    colorCode,
    baseColorCode
  ) {
    const targetMeshes = ["petal"];

    const textureLoader = new THREE.TextureLoader();

    // Load the new base color texture
    const newBaseColorTexture = textureLoader.load(baseColorTexturePath, () => {
      newBaseColorTexture.flipY = false;
      newBaseColorTexture.needsUpdate = true; // Ensure texture is updated after loading
    });

    modelRef.current.traverse((child) => {
      // if (child.isMesh && targetMeshes.includes(child.name)) {
      if (child.isMesh) {
        const material = child.material;

        if (material) {
          if (child.name.includes("petal") || child.name.includes("Petal") && !child.name.includes("zone")) {
            const color = colorCode.startsWith("#")
              ? colorCode
              : "#" + colorCode;
            material.color.set(color);
            material.map = newBaseColorTexture;
            material.normalmap = newNormalMapTexture;
            material.roughnessMap = newRoughnessMapTexture;
            material.needsUpdate = true;
          }
          if (child.name.includes("base")) {
            const color1 = baseColorCode.startsWith("#")
              ? baseColorCode
              : "#" + baseColorCode;
            material.color.set(color1);

            // material.map ={color:"0x"+colorCode.split('#')[0]} ;
            material.normalmap = newNormalMapBaseRose;
            material.roughnessMap = newRoughnessMapTexture;
            material.needsUpdate = true;
          }
        }
      }
    });
  }

  const init = () => {
    // Create scene

    const textureLoader = new THREE.TextureLoader();

    // Load your textures (update paths to your textures)
    newBaseColorTexture = textureLoader.load(
      "/textures/principal textures/red_02_E22202.png"
    );
    newNormalMapBaseRose = textureLoader.load(
      "/textures/principal textures/normal_base rosa.png"
    );
    newNormalMapTexture = textureLoader.load(
      "/textures/principal textures/Petal_Rose_Red_Normal.png"
    );
    newRoughnessMapTexture = textureLoader.load(
      "/textures/principal textures/rose_roughness.png"
    );

    const scene = new THREE.Scene();
    // scene.background = new THREE.Color(0xf0f0f0);
    sceneRef.current = scene;

    // Set up camera
    const camera = new THREE.PerspectiveCamera(
      45,
      canvasRef.current.clientWidth / canvasRef.current.clientHeight,
      0.01,
      1000
    );
    // camera.position.set(-0.242, 0.284, 0.2395);
    camera.position.set(
      -0.3518734965497957,
      0.23914218048842792,
      0.0804560829307368
    );

    // camera.lookAt(0, 0, 0)
    cameraRef.current = camera;

    // Set up renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setClearColor(0x000000, 0); // Make the background transparent
    renderer.setSize(
      canvasRef.current.clientWidth,
      canvasRef.current.clientHeight
    );

    // renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // renderer.toneMapping = THREE.NeutralToneMapping;
    renderer.toneMapping = THREE.LinearToneMapping;
    renderer.toneMappingExposure = 1;

    rendererRef.current = renderer;
    canvasRef.current.appendChild(renderer.domElement);

    // Set up OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.autoRotate = true
    controls.dampingFactor = 0.05;
    controls.enableZoom = true;
    orbitRef.current = controls;
    controls.addEventListener("change", logCameraPosition);

    const size = 10;
    const divisions = 10;

    const gridHelper = new THREE.GridHelper(size, divisions);
    // sceneRef.current.add(gridHelper);

    // Set up environment
    // const environment = new RoomEnvironment();
    // const pmremGenerator = new THREE.PMREMGenerator(renderer);
    // sceneRef.current.environment =
    //   pmremGenerator.fromScene(environment).texture;


    const loaderhdr = new RGBELoader();

    loaderhdr.load("/rostock_laage_airport_1k.hdr", function (texture) {
      // loaderhdr.load("https://storage.voody.app/neutral.hdr", function (texture) {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      sceneRef.current.environment = texture;  // Set the environment for the scene
      // sceneRef.current.background = new THREE.Color(0xffffff);  // Set the environment for the scene
    });



    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.68);
    // directionalLight.castShadow = true;
    directionalLight.position.set(0, 10, 7);
    sceneRef.current.add(directionalLight);


    setLoadingMain(true);

    // Load the GLTF model
    const loader = new GLTFLoader();
    loader.load(
      "/model/rosecenter_small._textpetal_new.glb",
      (gltf) => {
        console.log("gltf", gltf.scene);
        const model = gltf.scene;
        model.position.set(0, 0, 0);
        sceneRef.current.add(model);
        modelRef.current = model;
        setLoadModel(true);
      },
      (xhr) => {
        // Optionally, you can monitor loading progress here
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        setLoadingMain(false);
      },
      (error) => {
        console.error("An error happened", error);
      }
    );

    // Lighting
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(5, 5, 5);
    sceneRef.current.add(light);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    sceneRef.current.add(ambientLight);

    // Animate function
    const animate = () => {
      requestAnimationFrame(animate);
      orbitRef.current.update();
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      cameraRef.current.aspect =
        canvasRef.current.clientWidth / canvasRef.current.clientHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(
        canvasRef.current.clientWidth,
        canvasRef.current.clientHeight
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (rendererRef.current) rendererRef.current.dispose();
    };
  };


  function clearGLBModel() {
    sceneRef.current.traverse((object) => {
      if (object.isMesh) {
        // Dispose geometry
        if (object.geometry) object.geometry.dispose();

        // Dispose materials
        if (object.material) {
          if (Array.isArray(object.material)) {
            object.material.forEach((material) => {
              disposeMaterial(material);
            });
          } else {
            disposeMaterial(object.material);
          }
        }
      }
    });

    // Remove all children from the scene
    while (sceneRef.current.children.length > 0) {
      const child = sceneRef.current.children[0];
      sceneRef.current.remove(child);
    }

    console.log("Scene cleared!");
  }

  function loadGLBfile(url) {
    const loader = new GLTFLoader();

    // Load the GLB file
    loader.load(url, (gltf) => {
      modelRef.current = gltf.scene;
      sceneRef.current.add(gltf.scene);
  

      console.log('Model added to scene!', gltf.scene);
      const glassMaterial = new THREE.MeshPhysicalMaterial({
        color: 0xffffff,             // Base color
        roughness: 0,                // No roughness for clear glass
        transmission: 1,             // Enables transparency (1 = fully transparent)
        thickness: 0.001,              // Physical thickness of the object
        ior: 1.3,                    // Index of Refraction (glass = ~1.5)
        specularIntensity: 1,        // Specular highlights
        specularColor: 0xffffff,     // Color of specular highlights
        envMapIntensity: 0.8,          // Intensity of reflections
        metalness: 0,                // Non-metallic material
        clearcoat: 1,                // Add clearcoat for shine
        clearcoatRoughness: 0        // Keep clearcoat smooth
      });

      sceneRef.current.traverse(object => {
        if (object.isMesh && object.name.includes('Acrylic')) {
          console.log('object acrlic >>>', object)
          object.material = glassMaterial; // Assign the material
        }
      })


    });
  }

  // Helper to dispose of a material
  function disposeMaterial(material) {
    // Dispose of textures
    for (const key in material) {
      if (material[key] && material[key].isTexture) {
        material[key].dispose();
      }
    }
    material.dispose();
  }



  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      init(); // Call init function to set up Three.js
    }
  }, []);

  useEffect(() => {
    // let timer;
    if (loadModel) {
      // timer = setTimeout(() => {
      loadText(text);
      // }, 200);
    }
    // return () => clearTimeout(timer);
  }, [text, fontFamily, selectedColor, fontSize]);



  useEffect(() => {

    console.log('ROSE TYPE>>', roseType)
    if (firstLoad.current === false) {
      if (roseType === "Petal") {
        clearGLBModel()
        loadGLBfile('/model/Petals_red.glb')
        cameraRef.current.position.set(
          -0.49836787291952733, 0.39223931966196535, -0.10809927857100513
        );
      } else if (roseType === "Short-Stem-Rose") {
        clearGLBModel()
        loadGLBfile('/model/rosecenter_small._textpetal_new.glb')
        cameraRef.current.position.set(
          0.29515277672945694, 0.19344864575876264, -0.01275804984169258
        );
      }
    }

  }, [roseType]);



  useEffect(() => {

    console.log('material', material)
    console.log('quantity', quantity)
    console.log('boxSelected', boxSelected)
    if (firstLoad.current === false) {

      if (material === "acrylic") {
        clearGLBModel()
        loadGLBfile('/model/Acrylic_joined.glb')
        const loaderhdr = new RGBELoader();

        loaderhdr.load("/rostock_laage_airport_1k.hdr", function (texture) {
          // loaderhdr.load("https://storage.voody.app/neutral.hdr", function (texture) {
          texture.mapping = THREE.EquirectangularReflectionMapping;
          sceneRef.current.environment = texture;  // Set the environment for the scene
          // sceneRef.current.background = texture; 
        });
        const plan1 = new THREE.PlaneGeometry(5, 5)
        const material1 = new THREE.MeshBasicMaterial({ color: 0xFFFAFA, side: THREE.DoubleSide })
        const mesh1 = new THREE.Mesh(plan1, material1)
        mesh1.rotation.x = Math.PI / 2;
        sceneRef.current.add(mesh1)
  
        // تعریف Plane دوم که عمود است
        const geometry2 = new THREE.PlaneGeometry(5, 5);
        const material2 = new THREE.MeshBasicMaterial({ color: 0xFFFAFA, side: THREE.DoubleSide });
        const plane2 = new THREE.Mesh(geometry2, material2);
        sceneRef.current.add(plane2);
  
        // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
        plane2.position.set(0, 2.5,2.5); // تغییر مکان به ضلع بالای Plane اول
  
          // تعریف Plane دوم که عمود است
          const geometry3 = new THREE.PlaneGeometry(5, 5);
          const material3 = new THREE.MeshBasicMaterial({ color: 0xFFFAFA, side: THREE.DoubleSide });
          const plane3 = new THREE.Mesh(geometry3, material3);
          sceneRef.current.add(plane3);
    
          // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
          plane3.position.set(0, 2.5,-2.5); // تغییر مکان به ضلع بالای Plane اول
          plane3.rotation.y = Math.PI; // چرخش 180 درجه برای پشت
  
           // تعریف Plane دوم که عمود است
           const geometry4 = new THREE.PlaneGeometry(5, 5);
           const material4 = new THREE.MeshBasicMaterial({ color: 0xFFFAFA, side: THREE.DoubleSide });
           const plane4 = new THREE.Mesh(geometry4, material4);
           sceneRef.current.add(plane4);
     
           // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
           plane4.position.set(2.5, 2.5,0); // تغییر مکان به ضلع بالای Plane اول
           plane4.rotation.y = -Math.PI / 2; // چرخش 90 درجه روی محور Y (عمود بر Plane اول)
  
  
            // تعریف Plane دوم که عمود است
            const geometry5 = new THREE.PlaneGeometry(5, 5);
            const material5 = new THREE.MeshBasicMaterial({ color: 0xFFFAFA, side: THREE.DoubleSide });
            const plane5 = new THREE.Mesh(geometry5, material5);
            sceneRef.current.add(plane5);
      
            // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
            plane5.position.set(-2.5, 2.5,0); // تغییر مکان به ضلع بالای Plane اول
            plane5.rotation.y = Math.PI / 2; // چرخش 90 درجه روی محور Y (عمود بر Plane اول)
   
  


      } else if (material === "Paper") {
        clearGLBModel()
        loadGLBfile('/model/Paper_Box_Edited.glb')
        const loaderhdr = new RGBELoader();

        loaderhdr.load("/dancing_hall_1k (1).hdr", function (texture) {
          // loaderhdr.load("https://storage.voody.app/neutral.hdr", function (texture) {
          texture.mapping = THREE.EquirectangularReflectionMapping;
          sceneRef.current.environment = texture;  // Set the environment for the scene
        });

      }
    }

  }, [material, quantity, boxSelected]);


  useEffect(() => {
    if (colorRoseSelected && loadModel) {
      updateMeshBaseColorTexture(colorRoseSelected, colorCode, baseColorCode);
    }
  }, [colorRoseSelected]);

  const handleReset = () => {
    // alert("Reset");
    cameraRef.current.position.set(
      -0.3518734965497957,
      0.23914218048842792,
      0.0804560829307368
    );
  };

  const handleZoom = () => {
    // cameraRef.current.position.z = cameraRef.current.position.z - zoomStep
    if (cameraRef.current.position.z > minZoomDistance) {
      cameraRef.current.position.z -= 1; // Move camera closer
    }
  };

  const handleZoom2 = () => {
    // cameraRef.current.position.z = cameraRef.current.position.z + zoomStep
    if (cameraRef.current.position.z < maxZoomDistance) {
      cameraRef.current.position.z += 1; // Move camera closer
    }
  };

  return (
    <>
      <div className="CanvasPart">
        <div className="Logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="Canvas" ref={canvasRef}></div>

        <div className="CanvasPart-controler">
          <button className="CanvasPart-controler-item" onClick={handleReset}>
            <i
              className="bi bi-arrow-counterclockwise CanvasPart-controler-item-i"
              style={{
                transform: "rotate(.09turn)",
              }}
            ></i>
          </button>
          <button className="CanvasPart-controler-item">
            <i className="bi bi-dash-lg CanvasPart-controler-item-i"></i>
          </button>
          <button className="CanvasPart-controler-item">
            <i className="bi bi-plus-lg CanvasPart-controler-item-i"></i>
          </button>
        </div>
      </div>
    </>
  );
}
