import { useContext, useEffect, useState } from "react";
import rose3 from "../../../../assets/rose3.png";
import rose4 from "../../../../assets/rose4.png";
import left from "../../../../assets/left.png";
import Price from "./Price/Price";
import { HomeContext } from "../../Context/HomeContext";
import DesignRose from "./DesignRose/DesignRose";
import AddText from "./DesignRose/Components/AddText/AddText";
import AddImage from "./DesignRose/Components/AddImage/AddImage";
import ImageRules from "./DesignRose/Components/AddImage/ImageRules/ImageRules";
import AddClipart from "./DesignRose/Components/AddClipart/AddClipart";
import ImageUpload from "./DesignRose/Components/AddImage/ImageUpload/ImageUpload";
import Packaging from "./Packaging/Packaging";

export default function DesignPanel() {
  const [roseColor, setRoseColor , material] = useState("blu11");

  const {
    setColorRoseSelected,
    setColorCode,
    setBaseColorCode,
    designPanelMenu,
    setDesignPanelMenu,
    roseType,
    setRoseType,
    setMaterial
  } = useContext(HomeContext);

  const ROSE_COLORS = [
    {
      code: "blu1",
      hex: "#50CFD7",
      hexbase: "#50cfd7",
      texture: "/textures/colors textures/blu_02_50CFD7.png",
      check: "#000000",
    },
    {
      code: "blu2",
      hex: "#0700C5",
      hexbase: "#06018f",
      texture: "/textures/colors textures/blu_03_0700C5 .png",
      check: "#FFFFFF",
    },
    {
      code: "blu3",
      hex: "#7DB4D4",
      hexbase: "#7DB4D4",
      texture: "/textures/colors textures/blu_89_7DB4D4.png",
      check: "#000000",
    },
    {
      code: "blu4",
      hex: "#95989c",
      hexbase: "#95989c",
      texture: "/textures/colors textures/gry_02_95989c.png",
      check: "#000000",
    },

    {
      code: "blu5",
      hex: "#fcb785",
      hexbase: "#fbbe92",
      texture: "/textures/colors textures/pea_99_fcb785.png",
      check: "#000000",
    },
    {
      code: "blu6",
      hex: "#ceadb8",
      hexbase: "#d6b7c1",
      texture: "/textures/colors textures/pin_04_ceadb8.png",
      check: "#000000",
    },

    {
      code: "blu7",
      hex: "#F919AE",
      hexbase: "#a5135f",
      texture: "/textures/colors textures/pin_07_F919AE .png",
      check: "#FFFFFF",
    },

    {
      code: "blu8",
      hex: "#fbd0e9",
      hexbase: "#f2cde2",
      texture: "/textures/colors textures/pin_99_fbd0e9.png",
      check: "#000000",
    },

    {
      code: "blu9",
      hex: "#A1008E",
      hexbase: "#5d0052",
      texture: "/textures/colors textures/pur_02_A1008E .png",
      check: "#FFFFFF",
    },

    {
      code: "blu10",
      hex: "#790800",
      hexbase: "#640700",
      texture: "/textures/colors textures/red_01_790800.png",
      check: "#FFFFFF",
    },
    {
      code: "blu11",
      hex: "#E22202",
      hexbase: "#E22202",
      texture: "/textures/colors textures/red_02_E22202.png",
      check: "#FFFFFF",
    },
    {
      code: "blu12",
      hex: "#DBA8DC",
      hexbase: "#bb96bc",
      texture: "/textures/colors textures/vio_03_DBA8DC.png",
      check: "#000000",
    },
    {
      code: "blu13",
      hex: "#FFF7F7",
      hexbase: "#ebe2e2",
      texture: "/textures/colors textures/whi_01_FFF7F7 .png",
      check: "#000000",
    },

    {
      code: "blu14",
      hex: "#ECE1C2",
      hexbase: "#e8e2d2",
      texture: "/textures/colors textures/whi_04_ECE1C2.png",
      check: "#000000",
    },
    {
      code: "blu15",
      hex: "#FFD52F",
      hexbase: "#ffd52f",
      texture: "/textures/colors textures/yel_02_FFD52F.png",
      check: "#000000",
    },
  ];

  function RoseColor({ color, isActive, onClick }) {
    return (
      <div
        style={{
          border: `${isActive ? "2px" : "1px"} solid ${
            isActive ? "#000000" : color.hex
          }`,
        }}
        onClick={onClick}
      >
        <div
          className="Rose-color-item"
          style={{
            backgroundColor: color.hex,
          }}
        />
        <i
          className="bi bi-check-lg Rose-color-check"
          style={{
            color: color.check,
            display: isActive ? "block" : "none",
          }}
        ></i>
      </div>
    );
  }

  const handleRoseColorChange = (color) => {
    setRoseColor(color.code);
    setColorRoseSelected(color.texture);
    setColorCode(color.hex);
    setBaseColorCode(color.hexbase);
  };

  useEffect (() => {
    setMaterial(null);
  }, [])
  

  return (
    <>
      {designPanelMenu === 321 || designPanelMenu === 322 ? (
        <div className="DesignPanel-container col-lg-9">
          {designPanelMenu === 321 && <ImageRules />}

          {designPanelMenu === 322 && <ImageUpload />}
        </div>
      ) : (
        <div className="DesignPanel-container col-lg-9">
          <section className="DesignPanel-tools">
            <div>
              <h3 className="DesignPanel-tools-title">Design Your Own</h3>

              {designPanelMenu === 1 && (
                <div className="DesignPanel-tools-typeRose">
                  <div className="DesignPanel-tools-typeRose-container">
                    <div className="DesignPanel-tools-typeRose-title">
                      <span>Choose Type</span>
                    </div>

                    <div className="DesignPanel-tools-typeRose-content">
                      <section className="Rose-type ">
                        <div
                          className="Rose-type-item"
                          onClick={() => {
                            setRoseType("Short-Stem-Rose");
                          }}
                        >
                          <div
                            className={`Rose-type-item-image ${
                              roseType === "Short-Stem-Rose" ? "ActiveRose" : ""
                            }`}
                          >
                            <img src={rose3} alt="rose3" />
                          </div>
                          <span
                            className="Rose-type-item-text"
                            style={{
                              fontWeight: designPanelMenu === 1 ? "600" : "400",
                            }}
                          >
                            Short Stem Rose
                          </span>
                        </div>
                        <div
                          className="Rose-type-item"
                          onClick={() => {
                            setRoseType("Petal");
                          }}
                        >
                          <div
                            className={`Rose-type-item-image ${
                              roseType === "Petal" ? "ActiveRose" : ""
                            }`}
                          >
                            <img src={rose4} alt="rose4" />
                          </div>
                          <span className="Rose-type-item-text">Petal</span>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              )}

              {designPanelMenu === 2 && (
                <div className="DesignPanel-tools-colorRose">
                  <div className="DesignPanel-tools-colorRose-container">
                    <div className="DesignPanel-tools-colorRose-title">
                      <div
                        className="DesignPanel-tools-colorRose-title-backIcon"
                        onClick={() => {
                          setDesignPanelMenu(1);
                        }}
                      >
                        <img src={left} alt="left" />
                      </div>
                      <span>Choose Color</span>
                    </div>
                    <div className="DesignPanel-tools-colorRose-content">
                      <section className="Rose-color">
                        {ROSE_COLORS.map((color) => (
                          <RoseColor
                            key={color.code}
                            color={color}
                            isActive={roseColor === color.code}
                            onClick={() => handleRoseColorChange(color)}
                          />
                        ))}
                      </section>
                    </div>
                  </div>
                </div>
              )}

              {designPanelMenu === 3 && <DesignRose />}

              {designPanelMenu === 31 && <AddText />}

              {designPanelMenu === 32 && <AddImage />}

              {designPanelMenu === 33 && <AddClipart />}

              {designPanelMenu === 4 && <Packaging />}
            </div>
          </section>

          <section className="DesignPanel-price">
            <Price />
          </section>
        </div>
      )}
    </>
  );
}
