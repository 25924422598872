import { useContext, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import left from "../../../../../../../assets/left.png";
import Slider from "react-slick";

export default function AddText() {
  const {
    fontFamily,
    setFontFamily,
    text,
    setText,
    setFontFamilyPath,
    setDesignPanelMenu,
    workSpaceList,
    setWorkSpaceList,
    selectedColor,
    setSelectedColor,
    colors,
  } = useContext(HomeContext);

  const [activeTab, setActiveTab] = useState("New");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleTextChange = (e) => {
    if (e.target.value.length <= 14) {
      if (e.target.id === "line1") {
        setText({ ...text, line1: e.target.value });
      } else if (e.target.id === "line2") {
        setText({ ...text, line2: e.target.value });
      } else if (e.target.id === "line3") {
        setText({ ...text, line3: e.target.value });
      } else if (e.target.id === "line4") {
        setText({ ...text, line4: e.target.value });
      } else  {
        console.log('Error');
      }
      console.log(text);
    }
  };

  const fonts = [
    "Roboto",
    "Afrah",
    "Alexbrush",
    "Amazone",
    "Ananda",
    "Aristotelicasmallcaps",
    "Barcelony",
    "Bilboswashcaps",
    "Cabin",
    "Cardo",
    "Crimsonpro",
    "Exo2",
    "Fabulous",
    "Freehand521",
    "Gafta",
    "Heartandsoul",
    "Hello",
    "Hijrnotes",
    "Josephsophia",
    "Karla",
    "Kaufmann",
    "Lhandw",
    "Lobster",
    "Lora",
    "Lovelyhome",
    "Monotypecorsva",
    "Montserrat",
    "Montserrat2",
    "Neuton",
    "Opensans",
    "Pacifico",
    "Poppins",
    "Prozalibre",
    "Quicksand",
    "Waterfall",
  ];

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow next" onClick={onClick}>
        <i class="bi bi-chevron-right"></i>
      </div>
    );
  };

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow prev" onClick={onClick}>
        <i class="bi bi-chevron-left"></i>
      </div>
    );
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 3,
    nextArrow: currentSlide >= fonts.length - 4.5 ? null : <CustomNextArrow />,
    prevArrow: currentSlide === 0 ? null : <CustomPrevArrow />,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 3,
          nextArrow:
            currentSlide >= fonts.length - 4.2 ? null : <CustomNextArrow />,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          nextArrow:
            currentSlide >= fonts.length - 3.5 ? null : <CustomNextArrow />,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          nextArrow:
            currentSlide >= fonts.length - 3 ? null : <CustomNextArrow />,
          dots: false,
          infinite: false,
        },
      },
    ],
  };

  const CustomNextArrow2 = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow next" onClick={onClick}>
        <i class="bi bi-chevron-right"></i>
      </div>
    );
  };

  const CustomPrevArrow2 = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow prev" onClick={onClick}>
        <i class="bi bi-chevron-left"></i>
      </div>
    );
  };

  const [currentSlide2, setCurrentSlide2] = useState(0);

  const handleColorSelect = (color) => {
    setSelectedColor(color.hex);
  };

  let settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7.5,
    slidesToScroll: 8,
    nextArrow:
      currentSlide2 >= colors.length - 7.5 ? null : <CustomNextArrow2 />,
    prevArrow: currentSlide2 === 0 ? null : <CustomPrevArrow2 />,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide2(newIndex),
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 8,
          nextArrow:
            currentSlide2 >= colors.length - 10 ? null : <CustomNextArrow2 />,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 9.5,
          slidesToScroll: 8,
          nextArrow:
            currentSlide2 >= colors.length - 9.5 ? null : <CustomNextArrow2 />,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 7,
          nextArrow:
            currentSlide2 >= colors.length - 8 ? null : <CustomNextArrow2 />,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 6,
          nextArrow:
            currentSlide2 >= colors.length - 7 ? null : <CustomNextArrow2 />,
          dots: false,
          infinite: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="AddText">
        <div className="AddText-container">
          <div className="AddText-title">
            <div
              className="AddText-title-backIcon"
              onClick={() => {
                setDesignPanelMenu(3);
              }}
            >
              <img src={left} alt="left" />
            </div>
            <span>Add Text</span>
          </div>

          <div className="AddText-content">
            <div className="AddText-tab">
              <div
                onClick={() => handleTabClick("New")}
                className="AddText-tab-item"
                style={{
                  borderBottom:
                    activeTab === "New"
                      ? "2.5px solid #B04953"
                      : "2.5px solid #9C9C9C",
                  fontWeight: activeTab === "New" ? "bold" : "normal",
                  color: activeTab === "New" ? "#171A20" : "#5C5E62",
                  paddingRight: "5px",
                  paddingLeft: "5px",
                }}
              >
                New Text
              </div>
              <div
                onClick={() => handleTabClick("History")}
                className="AddText-tab-item"
                style={{
                  borderBottom:
                    activeTab === "History"
                      ? "2.5px solid #B04953"
                      : "2.5px solid #9C9C9C",
                  fontWeight: activeTab === "History" ? "bold" : "normal",
                  color: activeTab === "History" ? "#171A20" : "#5C5E62",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                History
              </div>
              <div
                onClick={() => handleTabClick("Suggestions")}
                className="AddText-tab-item"
                style={{
                  borderBottom:
                    activeTab === "Suggestions"
                      ? "2.5px solid #B04953"
                      : "2.5px solid #9C9C9C",
                  fontWeight: activeTab === "Suggestions" ? "bold" : "normal",
                  color: activeTab === "Suggestions" ? "#171A20" : "#5C5E62",
                  paddingRight: "5px",
                  paddingLeft: "5px",
                }}
              >
                Suggestions
              </div>
            </div>

            <div className="AddText-tab-content">
              {activeTab === "New" && (
                <div className="New">
                  <section className="New-text">
                    <div className="New-text-item">
                      <div className="New-text-item-title">Line 1: </div>
                      <textarea
                        id="line1"
                        className="New-text-item-textarea"
                        style={{
                          fontFamily,
                          color: selectedColor,
                          backgroundColor:
                            selectedColor === "#FFFFFF" ? "#808080" : "#ffffff",
                        }}
                        placeholder="Only 15 character"
                        maxLength={15}
                        value={text.line1}
                        onChange={handleTextChange}
                        wrap="hard"
                      />
                    </div>
                    <div className="New-text-item">
                      <div className="New-text-item-title">Line 2: </div>
                      <textarea
                        id="line2"
                        className="New-text-item-textarea"
                        style={{
                          fontFamily,
                          color: selectedColor,
                          backgroundColor:
                            selectedColor === "#FFFFFF" ? "#808080" : "#ffffff",
                        }}
                        placeholder="Only 15 character"
                        maxLength={15}
                        value={text.line2}
                        onChange={handleTextChange}
                        wrap="hard"
                      />
                    </div>
                    <div className="New-text-item">
                      <div className="New-text-item-title">Line 3: </div>
                      <textarea
                        id="line3"
                        className="New-text-item-textarea"
                        style={{
                          fontFamily,
                          color: selectedColor,
                          backgroundColor:
                            selectedColor === "#FFFFFF" ? "#808080" : "#ffffff",
                        }}
                        placeholder="Only 15 character"
                        maxLength={15}
                        value={text.line3}
                        onChange={handleTextChange}
                        wrap="hard"
                      />
                    </div>
                    <div className="New-text-item">
                      <div className="New-text-item-title">Line 4: </div>
                      <textarea
                        id="line4"
                        className="New-text-item-textarea"
                        style={{
                          fontFamily,
                          color: selectedColor,
                          backgroundColor:
                            selectedColor === "#FFFFFF" ? "#808080" : "#ffffff",
                        }}
                        placeholder="Only 15 character"
                        maxLength={15}
                        value={text.line4}
                        onChange={handleTextChange}
                        wrap="hard"
                      />
                    </div>
                  </section>

                  <section className="New-textFont">
                    <p className="New-textFont-title">Font Type</p>
                    <div className="New-textFont-carousel">
                      <Slider {...settings}>
                        {fonts.map((font, index) => (
                          <div
                            key={index}
                            className="New-textFont-carousel-item"
                          >
                            <div
                              className="New-textFont-carousel-item-button"
                              style={{
                                fontFamily: font,
                                backgroundColor:
                                  fontFamily === font ? "#ECB6BB" : "#ffffff",
                              }}
                              onClick={(e) => {
                                setFontFamilyPath(
                                  `/CustomizerFont/${e.target.innerHTML}.ttf`
                                );
                                setFontFamily(e.target.innerHTML);
                              }}
                            >
                              {font}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </section>

                  <section className="New-textColor">
                    <p className="New-textColor-title">Font color</p>
                    <div className="New-textColor-carousel">
                      <Slider {...settings2}>
                        {colors.map((color, index) => (
                          <div>
                            <div
                              key={index}
                              className="New-textColor-carousel-item"
                              style={{
                                border: `${
                                  selectedColor === color.hex ? "2px" : "1px"
                                } solid ${
                                  selectedColor === color.hex
                                    ? "#000000"
                                    : "#BFBFBF"
                                }`,
                              }}
                              onClick={() => handleColorSelect(color)}
                            >
                              <div
                                style={{
                                  backgroundColor: color.hex,
                                }}
                              />
                              <i
                                className="bi bi-check-lg New-textColor-carousel-item-check"
                                style={{
                                  color: color.check,
                                  display:
                                    selectedColor === color.hex
                                      ? "block"
                                      : "none",
                                }}
                              ></i>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </section>

                  
                </div>
              )}

              {activeTab === "History" && (
                <div className="History-text">
                  <section className="History-text-item">
                    <p>You are my Love</p>
                  </section>
                  <section className="History-text-item">
                    <p>You are my Love for all life</p>
                  </section>
                </div>
              )}

              {activeTab === "Suggestions" && (
                <div className="Suggestions-text">
                  <section className="Suggestions-text-item">
                    <p>Happy Mothers Day</p>
                  </section>
                  <section className="Suggestions-text-item">
                    <p>You are my Love for all life</p>
                  </section>
                </div>
              )}
            </div>
          </div>

          
        </div>

        <button className="Confirm">Confirm</button>
      </div>
      
    </>
  );
}
