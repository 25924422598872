import HomePage from "./Pages/Home/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/MI.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from "react";
import Login from "./Pages/Admin/Auth/Login";
import AdminPanel from "./Pages/Admin/AdminPanel/Admin";
import PanelContextProvider from "./Pages/Admin/Context/PanelContext";


function App() {

  useEffect(() => {
    document.title = "speakingRose";
  }, [])

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/login' element={<Login />} />
          <Route
            path='/adminPanel/*'
            element={
              <PanelContextProvider>
                <Routes>
                  <Route path='' element={<AdminPanel />} />
                </Routes>
              </PanelContextProvider>
            }
          />
        </Routes>
        
      </Router>

    </>
  );
}

export default App;
