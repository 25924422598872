import { useContext } from "react";
import { HomeContext } from "../../../Context/HomeContext";
import left from "../../../../../assets/left.png";
import textAdd from "../../../../../assets/textAdd.png";
import imageAdd from "../../../../../assets/imageAdd.png";
import clipartAdd from "../../../../../assets/clipartAdd.png";
import empty from "../../../../../assets/empty.png";

export default function DesignRose() {
  const {
    designPanelMenu,
    setDesignPanelMenu,
    workSpaceList,
    setWorkSpaceList,
  } = useContext(HomeContext);

  return (
    <>
      <div className="DesignPanel-tools-designRose">
        <div className="DesignPanel-tools-designRose-container">
          <div className="DesignPanel-tools-designRose-title">
            <div
              className="DesignPanel-tools-designRose-title-backIcon"
              onClick={() => {
                setDesignPanelMenu(2);
              }}
            >
              <img src={left} alt="left" />
            </div>
            <span>Design Your Rose</span>
          </div>

          <div className="DesignPanel-tools-designRose-content">
            <div>
              <section className="Add">
                <div
                  className="Add-item"
                  onClick={() => {
                    setDesignPanelMenu(31);
                  }}
                >
                  <div className="Add-item-icon">
                    <img src={textAdd} alt="textAdd" />
                  </div>
                  <div className="Add-item-text">Add Text</div>
                </div>
                <div
                  className="Add-item"
                  onClick={() => {
                    setDesignPanelMenu(32);
                  }}
                >
                  <div className="Add-item-icon">
                    <img src={imageAdd} alt="imageAdd" />
                  </div>
                  <div className="Add-item-text">Add Image</div>
                </div>
                <div
                  className="Add-item"
                  onClick={() => {
                    setDesignPanelMenu(33);
                  }}
                >
                  <div className="Add-item-icon">
                    <img src={clipartAdd} alt="clipartAdd" />
                  </div>
                  <div className="Add-item-text">Add Clipart</div>
                </div>
              </section>

              <section className="WorkSpace">
                <div>
                  <span className="WorkSpace-title">Your Work Space</span>
                  <div className="WorkSpace-content">
                    {workSpaceList ? (
                      <div></div>
                    ) : (
                      <div className="WorkSpace-content-empty">
                        <img src={empty} alt="empty" />
                        <p>There is no layer yet!</p>
                        <div>
                          Add your layers. you can add multiple layers of texts,
                          images and cliparts.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
