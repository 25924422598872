import { useState } from "react";
import { SketchPicker } from "react-color";

export default function ModalAddRoseColor() {
  const [paletMenu, setPaletMenu] = useState(false);
  const [color, setColor] = useState({
    code: "000000",
    hex: "#000000",
    rgb: { r: 0, g: 0, b: 0 },
  });

  const handleChange = (newColor) => {
    setPaletMenu(false);
    setColor({
      code: newColor.hex.slice(1).toUpperCase(),
      hex: newColor.hex,
      rgb: newColor.rgb,
    });
    console.log(color);
  };

  return (
    <div
      className="modal fade ModalAddRoseColor"
      id="ModalAddRoseColor"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog modal-dialog-centered ${
          paletMenu && "modal-lg"
        }`}
      >
        <div
          className="modal-content"
          style={{ width: paletMenu ? "70%" : "100%" }}
        >
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Add New Color
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="CodeOfColor">
              <label for="CodeOfColor" className="form-label">
                Code of Color:
              </label>
              <div className="CodeOfColor-input">
                <input
                  type="text"
                  className="form-control"
                  id="CodeOfColor"
                  value={color.code}
                />
                <button
                  onClick={() => {
                    setPaletMenu(true);
                  }}
                >
                  Choose From Pallet
                </button>
              </div>
            </div>

            <div className="Hex">
              <label for="Hex" className="form-label">
                Hex:
              </label>
              <input
                type="text"
                className="form-control"
                id="Hex"
                value={color.hex}
              />
            </div>

            <div className="RGB">
              <div className="RGB-item">
                <label for="Red" className="form-label">
                  Red:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Red"
                  value={color.rgb.r}
                />
              </div>

              <div className="RGB-item">
                <label for="Green" className="form-label">
                  Green:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Green"
                  value={color.rgb.g}
                />
              </div>

              <div className="RGB-item">
                <label for="Blue" className="form-label">
                  Blue:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Blue"
                  value={color.rgb.b}
                />
              </div>
            </div>

            <div className="ColorShow" style={{ backgroundColor : color.hex}}></div>
          </div>

          <div className="modal-footer SaveBtn">
            <button type="button" className="">
              Save
            </button>
          </div>

          {paletMenu && (
            <div className="Palet">
              <SketchPicker
                color={color}
                onChange={handleChange} // Called on color change (live preview)
                onChangeComplete={handleChange} // Called when user finishes selecting
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
