import { useContext } from "react";
import { PanelContext } from "../../../Context/PanelContext";
import left from "../../../../../assets/left.png";
import rose31 from "../../../../../assets/rose31.png";
import trash from "../../../../../assets/trash.png";
import edit from "../../../../../assets/edit-2.png";
import rose41 from "../../../../../assets/rose41.png";
import plus from "../../../../../assets/pLUS.png";
import ModalAddRoseType from "../Modal/AddRoseType/AddRoseType";
import ModalAddRoseColor from "../Modal/AddRoseColor/AddRoseColor";

export default function RoseConfiguration() {
  const { adminMenu, setAdminMenu } = useContext(PanelContext);

  return (
    <>
      <div className="RoseConfiguration">
        <div className="RoseConfiguration-title">
          <div
            className="RoseConfiguration-title-backIcon"
            onClick={() => {
              setAdminMenu(1);
            }}
          >
            <img src={left} alt="left" />
          </div>
          <h4>Rose Configurations</h4>
        </div>

        <div className="RoseConfiguration-content">
          <div>
            <section className="AddType">
              <div className="AddType-title">
                <h5>Add Type</h5>
              </div>

              <div className="AddType-content">
                <section className="AddType-card">
                  <div>
                    <div className="AddType-card-header">
                      <div className="AddType-card-header-img">
                        <img src={rose31} alt="rose" />
                      </div>

                      <span>Roses</span>
                    </div>

                    <div className="AddType-card-footer">
                      <div className="AddType-card-footer-item">
                        <img src={edit} alt="edit" />
                        <span>Edit</span>
                      </div>

                      <div className="AddType-card-footer-item">
                        <img src={trash} alt="edit" />
                        <span>Delete</span>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="AddType-card">
                  <div>
                    <div className="AddType-card-header">
                      <div className="AddType-card-header-img">
                        <img src={rose41} alt="rose" />
                      </div>
                      <span>Roses</span>
                    </div>

                    <div className="AddType-card-footer">
                      <div className="AddType-card-footer-item">
                        <img src={edit} alt="edit" />
                        <span>Edit</span>
                      </div>

                      <div className="AddType-card-footer-item">
                        <img src={trash} alt="edit" />
                        <span>Delete</span>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="AddType-cardPlus">
                  <div>
                    <div className="AddType-cardPlus-header">
                      <img src={plus} alt="rose" />
                      <span>Add New Type</span>
                      <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#ModalAddRoseType"
                    ></button>
                    </div>

                    <div className="AddType-cardPlus-footer"></div>
                  </div>
                </section>
              </div>
            </section>

            <section className="AddRoseColors">
              <div className="AddRoseColors-title">
                <h5>Add Colors</h5>
              </div>

              <div className="AddRoseColors-content">
                <section className="AddRoseColors-card">
                  <div>
                    <div
                      className="AddRoseColors-card-row1"
                      style={{
                        backgroundColor: "#DF818A",
                      }}
                    >
                      <div className="AddRoseColors-card-row1-item">
                        <img src={edit} alt="edit" />
                        <span>Edit</span>
                      </div>

                      <div className="AddRoseColors-card-row1-item">
                        <img src={trash} alt="edit" />
                        <span>Delete</span>
                      </div>
                    </div>

                    <div className="AddRoseColors-card-row2">
                      <section className="AddRoseColors-card-row2-item">
                        <span>Code:</span>
                        <span> 123456</span>
                      </section>

                      <section className="AddRoseColors-card-row2-item">
                        <span>Hex:</span>
                        <span>#DF818A</span>
                      </section>

                      <section className="AddRoseColors-card-row2-item">
                        <span>RGB: </span>
                        <span>223, 129, 138 </span>
                      </section>
                    </div>
                  </div>
                </section>

                <section className="AddRoseColors-card">
                  <div>
                    <div
                      className="AddRoseColors-card-row1"
                      style={{
                        backgroundColor: "#DF818A",
                      }}
                    >
                      <div className="AddRoseColors-card-row1-item">
                        <img src={edit} alt="edit" />
                        <span>Edit</span>
                      </div>

                      <div className="AddRoseColors-card-row1-item">
                        <img src={trash} alt="edit" />
                        <span>Delete</span>
                      </div>
                    </div>

                    <div className="AddRoseColors-card-row2">
                      <section className="AddRoseColors-card-row2-item">
                        <span>Code:</span>
                        <span> 123456</span>
                      </section>

                      <section className="AddRoseColors-card-row2-item">
                        <span>Hex:</span>
                        <span>#DF818A</span>
                      </section>

                      <section className="AddRoseColors-card-row2-item">
                        <span>RGB: </span>
                        <span>223, 129, 138 </span>
                      </section>
                    </div>
                  </div>
                </section>

                <section className="AddRoseColors-card">
                  <div>
                    <div
                      className="AddRoseColors-card-row1"
                      style={{
                        backgroundColor: "#DF818A",
                      }}
                    >
                      <div className="AddRoseColors-card-row1-item">
                        <img src={edit} alt="edit" />
                        <span>Edit</span>
                      </div>

                      <div className="AddRoseColors-card-row1-item">
                        <img src={trash} alt="edit" />
                        <span>Delete</span>
                      </div>
                    </div>

                    <div className="AddRoseColors-card-row2">
                      <section className="AddRoseColors-card-row2-item">
                        <span>Code:</span>
                        <span> 123456</span>
                      </section>

                      <section className="AddRoseColors-card-row2-item">
                        <span>Hex:</span>
                        <span>#DF818A</span>
                      </section>

                      <section className="AddRoseColors-card-row2-item">
                        <span>RGB: </span>
                        <span>223, 129, 138 </span>
                      </section>
                    </div>
                  </div>
                </section>

                <section className="AddRoseColors-cardPlus">
                  <div>
                    <div className="AddRoseColors-cardPlus-header">
                      <img src={plus} alt="rose" />
                      <span>Add New Color</span>
                      <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#ModalAddRoseColor"
                    ></button>
                    </div>

                    <div className="AddRoseColors-cardPlus-footer"></div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>

      <ModalAddRoseType/>

      <ModalAddRoseColor/>
    </>
  );
}
