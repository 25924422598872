import { useContext } from "react";
import { PanelContext } from "../../../Context/PanelContext";
import trash from "../../../../../assets/trash.png";
import edit from "../../../../../assets/edit-2.png";
import plus from "../../../../../assets/plus-white.png";
import ModalAddUser from "../Modal/AddUser/AddUser";

export default function UserManagment() {
  const { adminMenu, setAdminMenu } = useContext(PanelContext);

  const users = [
    { username: "UserName", password: "123456", email: "UserName@gmail.com" },
    { username: "UserName", password: "123456", email: "UserName@gmail.com" },
    { username: "UserName", password: "123456", email: "UserName@gmail.com" },
    { username: "UserName", password: "123456", email: "UserName@gmail.com" },
    { username: "UserName", password: "123456", email: "UserName@gmail.com" },
    { username: "UserName", password: "123456", email: "UserName@gmail.com" },
  ];

  return (
    <>
      <div className="UserManagment">
        <div className="UserManagment-title">
          <h4>User Managment</h4>
        </div>

        <div className="UserManagment-content">
          <div className="UserManagment-table">
            <div className="UserManagment-table-header">
              <span>UserName</span>
              <span>Password</span>
              <span>Email</span>
              <div className="add-user">
                <button
                  className="add-user-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalAddUser"
                >
                  <img src={plus} alt="plus" />
                  <p>Add New User</p>
                </button>
              </div>
            </div>

            <div className="UserManagment-table-body">
              {users.map((user, index) => (
                <div className="UserManagment-table-body-item" key={index}>
                  <span>{user.username}</span>
                  <span>{user.password}</span>
                  <span>{user.email}</span>
                  <div className="actions">
                    <button className="actions-btn">
                      <img src={edit} alt="edit" />
                      <span>Edit</span>
                    </button>
                    <button className="actions-btn">
                      <img src={trash} alt="trash" />
                      <span>Delete</span>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ModalAddUser />
    </>
  );
}
