import { useContext } from "react";
import { PanelContext } from "../../../Context/PanelContext";
import left from "../../../../../assets/left.png";
import trash from "../../../../../assets/trash.png";
import edit from "../../../../../assets/edit-2.png";
import plus from "../../../../../assets/pLUS.png";
import test1 from "../../../../../assets/test1.png";
import ModalAddBoxType from "../Modal/AddBoxType/AddBoxType";
import ModalAddBox from "../Modal/AddBox/AddBox";

export default function PackagingConfiguration() {
  const { adminMenu, setAdminMenu } = useContext(PanelContext);

  return (
    <>
      <div className="PackagingConfiguration">
        <div className="PackagingConfiguration-title">
          <div
            className="PackagingConfiguration-title-backIcon"
            onClick={() => {
              setAdminMenu(1);
            }}
          >
            <img src={left} alt="left" />
          </div>
          <h4>Packaging Configurations</h4>
        </div>

        <div className="PackagingConfiguration-content">
          <div>
            <section className="AddBoxType">
              <div className="AddBoxType-title">
                <h5>Add Box Type</h5>
              </div>

              <div className="AddBoxType-content">
                <section className="AddBoxType-item">
                  <p className="AddBoxType-item-title">Glass</p>
                  <div className="AddBoxType-item-icons">
                    <img src={edit} alt="edit" />
                    <img src={trash} alt="edit" />
                  </div>
                </section>

                <section className="AddBoxType-item">
                  <p className="AddBoxType-item-title">Paper</p>
                  <div className="AddBoxType-item-icons">
                    <img src={edit} alt="edit" />
                    <img src={trash} alt="edit" />
                  </div>
                </section>

                <section className="AddBoxType-plus">
                  <img src={plus} alt="edit" />
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#ModalAddBoxType"
                  ></button>
                </section>
              </div>
            </section>

            <section className="AddBox">
              <div className="AddBox-title">
                <h5>Add Box </h5>
              </div>

              <div className="AddBox-filter">
                <section className="AddBox-filter-item Item-active">
                  <p style={{ color: "#393C41", fontWeight: "700" }}> Glass</p>
                </section>

                <section className="AddBox-filter-item">
                  <p> Paper</p>
                </section>

                <section className="AddBox-filter-item">
                  <p> Rose</p>
                </section>

                <section className="AddBox-filter-item">
                  <p> Petals</p>
                </section>
              </div>

              <div className="AddBox-content">
                <section className="AddBox-cardPlis">
                  <img src={plus} alt="edit" />
                  <span>Add New Box</span>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#ModalAddBox"
                  ></button>
                </section>

                <section className="AddBox-card">
                  <div className="AddBox-card-image">
                    <img src={test1} alt="" />
                  </div>

                  <div className="AddBox-card-text">
                    <div className="AddBox-card-text-item">
                      <p>Box Material</p>
                      <span> Glass</span>
                    </div>
                    <div className="AddBox-card-text-item">
                      <p>Type:</p>
                      <span> Rose</span>
                    </div>
                    <div className="AddBox-card-text-item">
                      <p>Number:</p>
                      <span> 15</span>
                    </div>
                  </div>

                  <div className="AddBox-card-icons">
                    <img src={edit} alt="edit" />
                    <img src={trash} alt="edit" />
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>

      <ModalAddBoxType/>

      <ModalAddBox/>
    </>
  );
}
