import { createContext, useEffect, useState } from "react";

export const HomeContext = createContext();

export default function HomeContextProvider(props) {
  const colors = [
    {
      hex: "#000000",
      check: "#FFFFFF",
    },
    {
      hex: "#393C41",
      check: "#FFFFFF",
    },
    {
      hex: "#9C9C9C",
      check: "#000000",
    },
    {
      hex: "#FFFFFF",
      check: "#000000",
    },
    {
      hex: "#384B70",
      check: "#FFFFFF",
    },
    {
      hex: "#507687",
      check: "#FFFFFF",
    },
    {
      hex: "#FCFAEE",
      check: "#000000",
    },
    {
      hex: "#B8001F",
      check: "#FFFFFF",
    },
    {
      hex: "#A1D6B2",
      check: "#000000",
    },
    {
      hex: "#CEDF9F",
      check: "#000000",
    },
    {
      hex: "#F1F3C2",
      check: "#000000",
    },
    {
      hex: "#E8B86D",
      check: "#000000",
    },
    {
      hex: "#A28B55",
      check: "#000000",
    },
    {
      hex: "#86AB89",
      check: "#000000",
    },
    {
      hex: "#CBE2B5",
      check: "#000000",
    },
    {
      hex: "#E7FBE6",
      check: "#000000",
    },
    {
      hex: "#FF204E",
      check: "#FFFFFF",
    },
    {
      hex: "#A0153E",
      check: "#FFFFFF",
    },
    {
      hex: "#5D0E41",
      check: "#FFFFFF",
    },
    {
      hex: "#00224D",
      check: "#FFFFFF",
    },
    {
      hex: "#606C5D",
      check: "#FFFFFF",
    },
    {
      hex: "#FFF4F4",
      check: "#000000",
    },
    {
      hex: "#F7E6C4",
      check: "#000000",
    },
    {
      hex: "#F1C376",
      check: "#000000",
    },
  ];

  const [roseType, setRoseType] = useState("Short-Stem-Rose");

  const [designPanelMenu, setDesignPanelMenu] = useState(1);
  const [colorRoseSelected, setColorRoseSelected] = useState(
    "/textures/colors textures/red_02_E22202.png"
  );
  const [colorCode, setColorCode] = useState("#E22202");
  const [baseColorCode, setBaseColorCode] = useState("#E22202");

  const [fontFamily, setFontFamily] = useState("Roboto");
  const [fontFamilyPath, setFontFamilyPath] = useState(
    "/CustomizerFont/Roboto.ttf"
  );
  const [selectedColor, setSelectedColor] = useState(colors[0].hex);

  const [fontSize, setFontSize] = useState(14);
  const [text, setText] = useState({
    'line1' : "",
    'line2' : "",
    'line3' : "",
    'line4' : "",
  });

  const [material, setMaterial] = useState(null);
  const [quantity, setQuantity] = useState("1-Roses");

  const [boxSelected, setBoxSelected] = useState(
    "/static/media/ECUADORIAN_CARTOON.c75d4626a2a1ededca5e.png"
  );

  const [image, setImage] = useState(null);

  const [workSpaceList, setWorkSpaceList] = useState();

  const [loading, setLoading] = useState({});
  const [loadingMain, setLoadingMain] = useState(false);

  const contextValue = {
    roseType,
    setRoseType,
    colorRoseSelected,
    setColorRoseSelected,
    selectedColor,
    setSelectedColor,
    colors,
    fontFamily,
    setFontFamily,
    fontSize,
    setFontSize,
    text,
    setText,
    fontFamilyPath,
    setFontFamilyPath,
    material,
    setMaterial,
    boxSelected,
    setBoxSelected,
    loading,
    setLoading,
    loadingMain,
    setLoadingMain,
    colorCode,
    setColorCode,
    baseColorCode,
    setBaseColorCode,
    designPanelMenu,
    setDesignPanelMenu,
    image,
    setImage,
    workSpaceList,
    setWorkSpaceList,
    quantity,
    setQuantity,
  };
  return (
    <HomeContext.Provider value={contextValue}>
      {props.children}
    </HomeContext.Provider>
  );
}
