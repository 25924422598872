import { useContext} from "react";
import { HomeContext } from "../../../../../Context/HomeContext";


export default function Material() {
  
  const {material, setMaterial} = useContext(HomeContext);
  

  return (
    <>
      <div className="Rose-material">
        <h5 className="Rose-material-title">Choose Material</h5>
        <div className="Rose-material-list">
          <div
            className="Rose-material-list-item"
            style={{
              border:
                material === "acrylic"
                  ? "1px solid #000000"
                  : "0.5px solid #393C41",
              backgroundColor: material === "acrylic" ? "#EFBDC2" : "#ffffff",
            }}
            onClick={() => {
              setMaterial("acrylic");
            }}
          >
            <span
              style={{ fontWeight: material === "acrylic" ? "700" : "400" }}
            >
              acrylic
            </span>
          </div>

          <div
            className="Rose-material-list-item"
            style={{
              border:
                material === "Paper"
                  ? "1px solid #000000"
                  : "0.5px solid #393C41",
              backgroundColor: material === "Paper" ? "#EFBDC2" : "#ffffff",
            }}
            onClick={() => {
              setMaterial("Paper");
            }}
          >
            <span
              style={{ fontWeight: material === "Paper" ? "700" : "400" }}
            >
              Paper
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
