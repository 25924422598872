import { useContext, useEffect } from "react";
import left from "../../../../../assets/left.png";
import { HomeContext } from "../../../Context/HomeContext";
import Quabtity from "./Components/Quantity/Quantity";
import Material from "./Components/Material/Material";
import Boxes from "./Components/Boxes/Boxes";

export default function Packaging() {
  const { setDesignPanelMenu , setMaterial} = useContext(HomeContext);

  useEffect (() => {
    setMaterial("acrylic");

    return (() => {
      setMaterial(null);
    })
  } , [])

  return (
    <>
      <div className="DesignPanel-tools-packaging">
        <div className="DesignPanel-tools-packaging-container">
          <div className="DesignPanel-tools-packaging-title">
            <div
              className="DesignPanel-tools-packaging-title-backIcon"
              onClick={() => {
                setDesignPanelMenu(3);
              }}
            >
              <img src={left} alt="left" />
            </div>
            <span>Packaging</span>
          </div>
          <div className="DesignPanel-tools-packaging-content ">
            <Material />
            <Quabtity />
            <Boxes />
          </div>
        </div>
      </div>
    </>
  );
}
