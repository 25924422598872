import { useContext, useState } from "react";
import numberRose1 from "../../../../../../../assets/number-roses_Mesa de trabajo 1.jpg";
import numberRose3 from "../../../../../../../assets/number-roses-02.jpg";
import numberRose4 from "../../../../../../../assets/number-roses-03.jpg";
import numberRose6 from "../../../../../../../assets/number-roses-04.jpg";
import numberRose7 from "../../../../../../../assets/number-roses-05.jpg";
import numberRose9 from "../../../../../../../assets/number-roses-06.jpg";
import numberRose15 from "../../../../../../../assets/number-roses-07.jpg";
import numberRose16 from "../../../../../../../assets/number-roses-08.jpg";
import numberRose17 from "../../../../../../../assets/number-roses-09.jpg";
import numberRose18 from "../../../../../../../assets/number-roses-10.jpg";
import numberRose49 from "../../../../../../../assets/number-roses-11.jpg";
import { HomeContext } from "../../../../../Context/HomeContext";

export default function Quabtity() {
  const {quantity, setQuantity} = useContext(HomeContext);

  return (
    <>
      <section className="Rose-quantity">
        <h5 className="Rose-quantity-title">Quantity</h5>
        <div className="Rose-quantity-list">
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("1-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "1-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose1} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "1-Roses" ? "700" : "400" }}
            >
              1 Roses
            </p>
          </div>
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("3-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "3-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose3} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "3-Roses" ? "700" : "400" }}
            >
              3 Roses
            </p>
          </div>
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("4-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "4-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose4} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "4-Roses" ? "700" : "400" }}
            >
              4 Roses
            </p>
          </div>
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("6-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "6-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose6} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "6-Roses" ? "700" : "400" }}
            >
              6 Roses
            </p>
          </div>
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("7-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "7-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose7} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "7-Roses" ? "700" : "400" }}
            >
              7 Roses
            </p>
          </div>
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("9-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "9-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose9} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "9-Roses" ? "700" : "400" }}
            >
              9 Roses
            </p>
          </div>
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("15-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "15-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose15} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "15-Roses" ? "700" : "400" }}
            >
              15 Roses
            </p>
          </div>
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("16-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "16-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose16} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "16-Roses" ? "700" : "400" }}
            >
              16 Roses
            </p>
          </div>
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("17-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "17-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose17} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "17-Roses" ? "700" : "400" }}
            >
              17 Roses
            </p>
          </div>
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("18-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "18-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose18} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "18-Roses" ? "700" : "400" }}
            >
              18 Roses
            </p>
          </div>
          <div
            className="Rose-quantity-list-item"
            onClick={() => {
              setQuantity("49-Roses");
            }}
          >
            <div
              className="Rose-quantity-list-item-image"
              style={{
                border:
                  quantity === "49-Roses"
                    ? "2px solid #000000"
                    : "1px solid #393C41",
              }}
            >
              <img src={numberRose49} alt="" />
            </div>
            <p
              className="Rose-quantity-list-item-text"
              style={{ fontWeight: quantity === "49-Roses" ? "700" : "400" }}
            >
              49 Roses
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
