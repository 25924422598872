import { useContext, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import left from "../../../../../../../assets/left.png";

export default function AddClipart() {
  const { setDesignPanelMenu, workSpaceList, setWorkSpaceList } =
    useContext(HomeContext);

  const [activeTab, setActiveTab] = useState("New");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="AddClipart">
        <div className="AddClipart-container">
          <div className="AddClipart-title">
            <div
              className="AddClipart-title-backIcon"
              onClick={() => {
                setDesignPanelMenu(3);
              }}
            >
              <img src={left} alt="left" />
            </div>
            <span>Add Clipart</span>
          </div>

          <div className="AddClipart-content">
            <div>
              <div className="AddClipart-tab">
                <div
                  onClick={() => handleTabClick("New")}
                  className="AddClipart-tab-item"
                  style={{
                    borderBottom:
                      activeTab === "New"
                        ? "2.5px solid #B04953"
                        : "2.5px solid #9C9C9C",
                    fontWeight: activeTab === "New" ? "bold" : "normal",
                    color: activeTab === "New" ? "#171A20" : "#5C5E62",
                    paddingRight: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  New Clipart
                </div>
                <div
                  onClick={() => handleTabClick("History")}
                  className="AddClipart-tab-item"
                  style={{
                    borderBottom:
                      activeTab === "History"
                        ? "2.5px solid #B04953"
                        : "2.5px solid #9C9C9C",
                    fontWeight: activeTab === "History" ? "bold" : "normal",
                    color: activeTab === "History" ? "#171A20" : "#5C5E62",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  History
                </div>
              </div>

              <div className="AddClipart-tab-content">
                {activeTab === "New" && (
                  <div className="New-Clipart">
                    <div className="New-Clipart-category">
                      <label htmlFor="" className="New-Clipart-category-title">
                        Select Category
                      </label>
                      <select name="" id="" className="New-Clipart-category-list">
                        <option value="">Christmas</option>
                      </select>
                    </div>
                    <div className="New-Clipart-list"></div>
                  </div>
                )}

                {activeTab === "History" && (
                  <div className="History-Clipart"></div>
                )}
              </div>
            </div>
          </div>

          <button className="confirmBtn">Confirm</button>
        </div>
      </div>
    </>
  );
}
